<template>
    <section>
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   class="loaderPreview"
                   rounded="sm">


            <b-row v-if="(meta.breadcrumb || meta.pageTitle) && dataLoaded"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="6">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ meta.pageTitle }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="6"
                       cols="12">
                    <b-button v-b-modal.addContactmodal
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="outline-success"
                              @click="addProject">
                        <feather-icon icon="PlusIcon" size="18" />
                        New Contact
                    </b-button>


                    <download-excel class="btn btn-outline-primary ml-1" v-if="totalRows>0"
                                    :data="items"
                                    :fields="Csvfields"
                                    type="csv"
                                    name="Landingly Contacts.xls">
                        <feather-icon icon="FileIcon" size="18" />
                        Download CSV
                    </download-excel>

                </b-col>
            </b-row>

            <b-row class="match-height" v-if="dataLoaded">
                <b-col lg="12">
                    <b-card>
                        <div class="pb-2">
                            <div class="row d-flex justify-content-between flex-wrap">
                                <b-col lg="4">
                                </b-col>
                                <b-col lg="4">
                                    <b-form-group label="Search" v-if="totalRows>0"
                                                  label-cols-sm="3"
                                                  label-align-sm="right"
                                                  label-size="sm"
                                                  label-for="SearchInput"
                                                  class="mb-0 align-items-center">
                                        <b-input-group size="sm">
                                            <b-form-input id="SearchInput"
                                                          v-model="SearchText"
                                                          type="text"
                                                          placeholder="Enter Name or Email"
                                                          @keyup="searchContact()" />
                                            <b-input-group-append>
                                                <b-button :disabled="!SearchText" @click="clearSearchField">
                                                    Clear
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <!--<b-col-2>
                                    <b-dropdown variant="transparent"
                                                text="Select Columns"
                                                checkbox-menu
                                                allow-focus>

                                        <b-dropdown-form>
                                            <b-form-checkbox v-for="field in fields"
                                                             :id="field.key"
                                                             :value="field.key">
                                                {{field.label}}
                                            </b-form-checkbox>
                                        </b-dropdown-form>

                                    </b-dropdown>
                                </b-col-2>-->
                            </div>

                        </div>

                        <b-table striped
                                 bordered
                                 hover
                                 responsive
                                 fixed
                                 class="vgt-table bordered contactsList"
                                 :current-page="currentPage"
                                 :items="items"
                                 :fields="fields"
                                 :sort-by.sync="sortBy"
                                 :sort-desc.sync="sortDesc"
                                 @filtered="onFiltered"
                                 show-empty>
                            <template #empty="scope">
                                Record doesn't exist
                            </template>
                            <template v-slot:cell(Id)="data">
                                <b-button size="sm"
                                          title="Edit"
                                          v-b-modal.editContact
                                          type="button"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          v-b-tooltip.hover.v-info
                                          variant="flat-info"
                                          class="btn-icon"
                                          @click="showEditModal(data.item.Id,data.item.Email,data.item.PrimaryEmailId,data.item.ContactSubscriberId,data.item.FirstName,data.item.LastName,data.item.ProjectId,data.item.IsSubscribed)">
                                    <feather-icon icon="EditIcon" size="13" />
                                </b-button>

                                <b-button title="Projects"
                                          size="sm"
                                          class="btn-icon"
                                          v-b-modal.projectmodal
                                          type="button"
                                          v-b-tooltip.hover.v-primary
                                          variant="flat-primary"
                                          @click="showProject(data.item.Id)">
                                    <feather-icon icon="PackageIcon" size="13" />
                                </b-button>


                                <b-button v-if="hasAdditionalInfo(data.item)"
                                          title="Additional Info"
                                          size="sm"
                                          type="button"
                                          class="btn-icon"
                                          v-b-modal.contactInfo
                                          variant="flat-success"
                                          @click="showAdditionalInfo(data.item.AddressLineOne,data.item.AddressLineTwo,data.item.City,data.item.State,data.item.PostalCode,data.item.CountryName,data.item.PhoneNo)"
                                          v-b-tooltip.hover.v-success>
                                    <feather-icon icon="InfoIcon" size="13" />
                                </b-button>

                            </template>
                            <template #cell(date)="data">
                                <span>
                                    {{data.item.RecCreatedDt | moment("DD-MMM-YYYY hh:mm:ss A")}}
                                </span>
                            </template>
                        </b-table>

                        <b-card-body class="row d-flex justify-content-between flex-wrap pt-0">
                            <b-form-group label="Show"
                                          label-cols="4"
                                          label-align="center"
                                          label-size="sm"
                                          label-for="sortBySelect"
                                          class="text-nowrap mb-md-0 mr-1">
                                <b-form-select id="perPageSelect"
                                               v-model="perPage"
                                               size="sm"
                                               @change="searchContact()"
                                               inline
                                               :options="pageOptions" />
                            </b-form-group>
                            <div v-if="totalRows > perPage">
                                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                                    <div>
                                        <b-pagination v-model="currentPage"
                                                      :total-rows="totalRows"
                                                      :per-page="perPage"
                                                      first-number
                                                      last-number
                                                      prev-class="prev-item"
                                                      next-class="next-item"
                                                      class="mb-0"
                                                      @change="loadPage">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </b-card-body>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-modal hide-footer
                     id="projectmodal"
                     centered
                     size="lg"
                     title="Associated Projects">
                <b-table fixed
                         bordered
                         responsive
                         :fields="projectfields"
                         :items="projects">
                    <template #cell(ProjectName)="data">
                        <!-- <b-link :href="`/Contacts/ProjectContacts/`+ ProjectId">
                            {{ data.value }}
                            </b-link> -->

                        <b-link :to="`/projectcontacts/` + ProjectId" target="_blank">
                            <span class="name">{{ data.value }}</span>
                        </b-link>
                    </template>
                    <template #cell(Status)="data">
                        <label v-if="data.item.IsSubscribed == false">Unsubscribed</label>
                        <label v-if="data.item.IsSubscribed == true">Subscribed</label>
                    </template>
                    <template #cell(actions)="data">
                        <b-button size="sm"
                                  v-if="data.item.IsSubscribed == false"
                                  type="button"
                                  variant="success"
                                  class="border border-light"
                                  @click="subscribeProject(data.item.Id, data.item.ProjectId, data)">
                            Add
                        </b-button>
                        <b-button size="sm"
                                  v-else
                                  type="button"
                                  variant="danger"
                                  class="border border-light"
                                  @click="unsubscribe(data.item.Id, data.item.ProjectId, data)">
                            Remove
                        </b-button>

                        <!--<b-button size="sm"
                                  v-if="data.item.IsSubscribed"
                                  v-b-modal.projectmodal
                                  type="button"
                                  variant="success"
                                  class="border border-light"
                                  @click="subscribeProject(data.item.Id, data.item.ProjectId, data)">
                            Yes
                        </b-button>
                        <b-button size="sm"
                                  v-else
                                  v-b-modal.projectmodal
                                  type="button"
                                  variant="default"
                                  class="border border-light"
                                  @click="subscribeProject(data.item.Id, data.item.ProjectId, data)">
                            Yes
                        </b-button>-->
                    </template>
                </b-table>
            </b-modal>

            <b-modal id="addContactmodal" centered size="lg" title="Add Contact">
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>
                            <!-- username -->
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="Name">Name<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         name="Name"
                                                         rules="required">
                                        <b-form-input id="Name"
                                                      v-model="FirstName"
                                                      :state="errors.length > 0 ? false : null"
                                                      placeholder="Please Enter Contact Name" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- email -->
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="Email">Email<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         name="Email"
                                                         rules="required|email">
                                        <b-form-input id="Email"
                                                      v-model="Email"
                                                      type="email"
                                                      :state="errors.length > 0 ? false : null"
                                                      placeholder="Please Enter Contact Email" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- select Project -->
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="SelectProjects">Select Projects<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         name="SelectProjects"
                                                         rules="required">
                                        <!-- <multiselect v-model="SelectProjects" :options="ProjectOptions" :multiple="true"></multiselect> -->
                                        <multiselect name="ProjectIds"
                                                     id="ProjectIds"
                                                     track-by="Id"
                                                     label="Name"
                                                     variant="default"
                                                     v-model="SelectProjects"
                                                     :options="UserFunnels"
                                                     key="Id"
                                                     value="Id"
                                                     return-object
                                                     multiple
                                                     :class="errors.length > 0 ? 'error' : 'valid'"
                                                     :state="errors.length > 0 ? false : null"></multiselect>
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <b-row>
                                        <b-col lg="2">
                                            <label> Is Subscribed? </label>
                                        </b-col>
                                        <b-button size="sm"
                                                  type="button"
                                                  :variant="IsSubscribed == false?'danger':'default'"
                                                  class="border border-light"
                                                  v-model="IsSubscribed"
                                                  value="false"
                                                  @click="IsSubscribed=false">
                                            No
                                        </b-button>


                                        <b-button size="sm"
                                                  type="button"
                                                  :variant="IsSubscribed == true?'success':'default'"
                                                  class="border border-light"
                                                  v-model="IsSubscribed"
                                                  value="true"
                                                  @click="IsSubscribed=true">
                                            Yes
                                        </b-button>
                                    </b-row>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <b-row>
                                        <b-col lg="4">
                                            <label> Send Project Automation Emails? </label>
                                        </b-col>
                                        <b-button size="sm"
                                                  class="border border-light"
                                                  :variant="ProjectAutomation == false?'danger':'default'"
                                                  type="button"
                                                  v-model="ProjectAutomation"
                                                  value="false"
                                                  @click="ProjectAutomation=false">
                                            No
                                        </b-button>

                                        <b-button size="sm"
                                                  class="border border-light"
                                                  type="button"
                                                  :variant="ProjectAutomation == true?'success':'default'"
                                                  v-model="ProjectAutomation"
                                                  value="true"
                                                  @click="ProjectAutomation=true">
                                            Yes
                                        </b-button>

                                    </b-row>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeAddContactModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>
                    <b-button @click="addContactForm"
                              id="btnSaveContact"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>
                </template>
            </b-modal>

            <b-modal id="editContact" centered size="lg" title="Update Contact">
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>
                            <!-- username -->
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="Name">Name<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         name="Name"
                                                         rules="required">
                                        <b-form-input id="Name"
                                                      v-model="FirstName"
                                                      :state="errors.length > 0 ? false : null"
                                                      placeholder="Please Enter Contact Name" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- password -->
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="Email">Email<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         name="Email"
                                                         rules="required|email">
                                        <b-form-input id="Email"
                                                      v-model="Email"
                                                      type="email"
                                                      :state="errors.length > 0 ? false : null"
                                                      placeholder="Please Enter Contact Email" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <!--<input id="unsubscribeCheckbox"
                                       v-model="subscribe"
                                       name="unsubscribeCheckbox"
                                       type="checkbox"
                                       @change="showAlert()" />

                                Unsubscribe From All Projects-->
                                <b-form-checkbox v-model="subscribe"
                                                 id="unsubscribeCheckbox"
                                                 name="unsubscribeCheckbox"
                                                 :disabled="subscribe"
                                                 @change="showAlert()">
                                    Unsubscribe From All Projects
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
                <slot></slot>
                <template #modal-footer="{ cancel, ok }">

                    <b-button @click="closeContactUpdateModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="submit"
                              @click.prevent="SaveEditForm">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>
                </template>
            </b-modal>

            <b-modal id="contactInfo" ref="modal" centered size="lg" title="Additional Information">
                <div v-if="additionalInfoRows.length > 0">
                    <table class="table table-bordered verticle-table">
                        <tbody v-for="(row, index) in additionalInfoRows" :key="index">
                            <tr v-for="(value, key) in row" v-if="value !== null && value !== undefined && value !== ''">
                                <th>
                                    {{ getColumnName(key) }}
                                </th>
                                <td>
                                    {{ value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <slot></slot>

                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeContactInfoModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Close
                    </b-button>
                </template>

            </b-modal>

        </b-overlay>
    </section>
</template>

<style scoped>
    .verticle-table th {
        background-color: #f3f2f7;
        border: 1px solid #ebe9f1 !important;
        border-bottom-color: #e1dee9 !important;
        border-right-width: 2px;
    }

    body.dark-layout .verticle-table th {
        background-color: #343d55;
        border-color: #3b4253 !important;
        border-bottom-color: #464d60 !important;
    }

    .verticle-table td:nth-of-type(odd) {
        background-color: #fafafc;
    }

    body.dark-layout .verticle-table td:nth-of-type(odd) {
        background-color: #242b3d;
    }
</style>

<script>
    import Ripple from "vue-ripple-directive";
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
    } from "@validations";
    import BCardCode from "@core/components/b-card-code/BCardCode.vue";
    import {
        BOverlay,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormCheckbox,
        BCard,
        BCardBody,
        BForm,
        BRow,
        BCol,
        BLink,
        BBreadcrumb, BBreadcrumbItem,
        VBTooltip,
        BDropdown,
        BDropdownForm,
        BTr, BTd, BTh,
        BContainer, BListGroup, BListGroupItem
    } from "bootstrap-vue";
    import { codeKitchenSink } from "../../table/bs-table/code.js";
    import jwt from "@/auth/jwt/useJwt";
    import { $themeConfig } from "@themeConfig";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import Multiselect from "vue-multiselect";
    import moment from "moment";
    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });
    export default {
        metaInfo: {
            title: 'Landingly | Contacts'
        },
        components: {
            BCard,
            Ripple,
            Multiselect,
            BOverlay,
            BCardCode,
            BForm,
            BRow,
            BCol,
            BTable,
            ValidationProvider,
            ValidationObserver,
            BAvatar,
            BBadge,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BFormCheckbox,
            BCardBody,
            BLink,
            BBreadcrumb,
            BBreadcrumbItem,
            BDropdown,
            BDropdownForm,
            BTh, BTd, BTr,
            BContainer, BListGroup, BListGroupItem
        },
        data() {
            return {
                isModalVisible: false,
                variant: "light",
                opacity: 0.85,
                blur: "2px",

                meta: {
                    pageTitle: 'Contacts',
                    breadcrumb: [
                        {
                            text: 'Contacts',
                            active: true
                        },
                    ],
                },

                loading: true,
                modalShow: false,

                dataLoaded: false,

                WebAPIURL: $themeConfig.app.apiURL,
                required,
                email,
                Email: "",
                SelectProjects: null,
                ProjectAutomation: true,
                ProjectOptions: ["list", "of", "options"],
                hdnContactId: 0,
                editmodal: false,
                subscribe: false,
                PrimaryEmailId: 0,
                ContactSubscriberId: 0,
                Name: "",
                LastName: "",
                ProjectId: 0,
                IsSubscribed: true,
                perPage: 20,
                pageOptions: [10, 20, 50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: "",
                sortDesc: false,
                sortDirection: "asc",
                filter: null,
                SearchText: null,
                filterOn: [],
                infoModal: {
                    id: "info-modal",
                    title: "",
                    content: "",
                },
                Csvfields: {
                    Name: {
                        callback: (row) => {
                            if (row.FullName !== "") {
                                return row.FullName;
                            }
                            else {
                                return `${row.FirstName} ${row.LastName}`
                            }
                        }
                    },
                    Email: "Email",
                    "Optin Date": "RecCreatedDt",
                    "Address One": "AddressLineOne",
                    "Address Two": "AddressLineTwo",
                    "City": "City",
                    "Country Name": "CountryName",
                    "Postal Code": "PostalCode",
                    "State": "State",
                    "Phone No": "PhoneNo"

                },
                fields: [
                    {
                        key: "FullName", label: "Name", sortable: true,
                        formatter: (value, key, item) => {
                            if (item.FullName !== null && item.FullName.trim() !== "") {
                                return item.FullName;
                            } else {
                                return item.FirstName + " " + item.LastName;
                            }
                        },
                    },
                    { key: "Email", label: "Email", sortable: true, },

                    {
                        key: "date", label: "Optin Date", sortable: true,
                        thStyle: { width: "250px" },
                    },
                    {
                        key: "Id",
                        label: "Actions",
                        sortable: false,
                        thStyle: { width: "170px" },
                    },
                ],
                UserFunnels: [{ FunnelGoalId: 0 }, { Name: null }],
                /* eslint-disable global-require */
                items: null,
                projectfields: [
                    { key: "ProjectName", label: "Project Name" },
                    { key: "Status", label: "Status" },
                    { key: "actions", label: "Action" },
                ],
                projects: [
                    { Id: null },
                    { ProjectName: "" },
                    { IsSubscribed: "" },
                    { ContactEmail: "" },
                ],

                additionalInfoRows: [], // Array to hold additional information
                //additionalInfoFields: [
                //    { key: 'AddressLineOne', label: 'Address One', visible:true },
                //    { key: 'AddressLineTwo', label: 'Address Two', visible: true },
                //    { key: 'City', label: 'City', visible: true},
                //    { key: 'State', label: 'State', visible: true},
                //    { key: 'PostalCode', label: 'Postal Code', visible: true},
                //],

                /* eslint-disable global-require */
                status: [
                    {
                        1: "Current",
                        2: "Professional",
                        3: "Rejected",
                        4: "Resigned",
                        5: "Applied",
                    },
                    {
                        1: "light-primary",
                        2: "light-success",
                        3: "light-danger",
                        4: "light-warning",
                        5: "light-info",
                    },
                ],
                codeKitchenSink,
            };
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
            },

            visibleAdditionalInfoFields() {
                return this.additionalInfoFields.filter(field => field.visible);
            },
        },
        created() {
            this.getContacts(1);
        },
        beforeCreate() {
           
        },
        mounted() {
            //document.querySelector('a[href*="/contacts"]').parentNode.classList.add("active");
            // Set the initial number of items
            //this.totalRows = this.items.length


            //jwt.refreshAccessToken();

            //this.getTotalCount();
        },
        methods: {
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`;
                this.infoModal.content = JSON.stringify(item, null, 2);
                this.$root.$emit("bv::show::modal", this.infoModal.id, button);
            },
            resetInfoModal() {
                this.infoModal.title = "";
                this.infoModal.content = "";
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                //this.totalRows = filteredItems.length
                //this.currentPage = 1
            },

            closeContactUpdateModal() {
                this.$bvModal.hide("editContact");
            },

            closeAddContactModal() {
                this.$bvModal.hide("addContactmodal");
            },
            async getContacts(pagenumber) {
                this.currentPage = pagenumber;
                var self = this;
                var searchvalue = this.SearchText;
                var startValue = (pagenumber - 1) * this.perPage;
                var length = this.perPage;

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/getConatcts?searchValue=" + searchvalue + "&startValue=" + startValue + "&length=" + length + "&IsServerSide=" + true, { headers })
                    .then((response) => {
                        self.items = response.data;
                        var i;
                        for (i = 0; i < self.items.length; i++) {
                            self.items[i].RecCreatedDt = moment(self.items[i].RecCreatedDt).format("DD-MMM-YYYY hh:mm:ss A");
                        }

                        if (response.data.length > 0) {
                            this.totalRows = response.data[0].TotalCount;
                        } else {
                            this.totalRows = 0;
                        }
                        this.modalShow = true;
                        self.loading = false;
                        self.dataLoaded = true;
                    });
            },
            async getTotalCount() {
                var self = this;

                this.loading = true;
                var axios = require("axios");
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/contactsCount", { headers })
                    .then((response) => {
                        //this.loading = false;
                        self.totalRows = String(response.data.Counts);
                    });
            },
            loadPage(pagenumber) {
                this.getContacts(pagenumber);
            },
            showEditModal(Id, email, PrimaryEmailId, ContactSubscriberId, FirstName, LastName, ProjectId, IsSubscribed) {
                this.hdnContactId = Id;
                this.FirstName = FirstName;
                this.Email = email;
                this.PrimaryEmailId = PrimaryEmailId;
                this.ContactSubscriberId = ContactSubscriberId;
                this.LastName = LastName;
                this.ProjectId = ProjectId;
                this.IsSubscribed = IsSubscribed;

                if (!IsSubscribed) {
                    this.subscribe = true;
                }
                else {
                    this.subscribe = false;
                }

                this.isModalVisible = true;
                //('#editContact').modal('show');
            },

            hasAdditionalInfo(item) {
                return (
                    item.AddressLineOne != null && item.AddressLineOne !== '' ||
                    item.AddressLineTwo != null && item.AddressLineTwo !== '' ||
                    item.City != null && item.City !== '' ||
                    item.State != null && item.State !== '' ||
                    item.PostalCode != null && item.PostalCode !== '' ||
                    item.PhoneNo != null && item.PhoneNo !== '' ||
                    item.CountryName != null && item.CountryName !== ''
                );
            },

            closeContactInfoModal() {
                this.$bvModal.hide("contactInfo");
            },

            showAdditionalInfo(AddressLineOne, AddressLineTwo, City, State, PostalCode, CountryName , PhoneNo) {
                this.additionalInfoRows = [];

                this.additionalInfoRows.push({
                    AddressLineOne,
                    AddressLineTwo,
                    City,
                    State,
                    PostalCode,
                    CountryName,
                    PhoneNo
                });

               // this.updateFieldVisibility();

            },

            getColumnName(key) {
                // Customize column labels based on keys
                switch (key) {
                    case 'AddressLineOne':
                        return 'Address One';
                    case 'AddressLineTwo':
                        return 'Address Two';
                    case 'PostalCode':
                        return 'Postal Code';
                    case 'CountryName':
                        return 'Country Name';
                    default:
                        return key; // Use the key itself if no custom label is defined
                }
            },

            //updateFieldVisibility() {
            //    this.additionalInfoFields.forEach(field => {
            //        const key = field.key;
            //        field.visible = this.additionalInfoRows.some(row => row[key] != null && row[key] !== '');
            //    });
            //},

            showAlert() {
                //if (this.subscribe) {
                //    this.$swal(
                //        "Warning",
                //        "This will unsubscribe this contact from all projects and this action is not reversible.",
                //        "warning"
                //    );
                //}
                var self = this;

                if (this.subscribe) {
                    this.$swal({
                        title: "Unsubscribe",
                        text: "Are you sure you want to Unsubscribe From All Projects? This action is not reversible.",
                        //icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Unsubscribe",
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {
                            self.subscribe = true;
                        }
                        else {
                            self.subscribe = false;
                        }
                    });
                }
            },
            async SaveEditForm() {
                const access_token = await jwt.getToken();
                this.$refs.simpleRules.validate().then((success) => {
                    if (success) {
                        var self = this;
                        this.loading = true;

                        var axios = require("axios");
                        var qs = require("qs");
                        var data = qs.stringify({});

                        var config = {
                            method: "post",
                            url: "" + this.WebAPIURL + "/api/ContactApi/UpdateContactInfo?hdnContactId=" + self.hdnContactId + "&PrimaryEmailId=" + self.PrimaryEmailId + "&ContactSubscriberId=" + self.ContactSubscriberId + "&FirstName=" + self.FirstName + "&LastName=" + self.LastName + "&PrimaryEmail=" + encodeURIComponent(encodeURIComponent(self.Email)) + "&ProjectId=" + self.ProjectId + "&IsSubscribed=" + self.IsSubscribed + "&UnSubscribeFromAll=" + self.subscribe,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                if (response.data == "Saved") {
                                    self.$swal(
                                        "Success",
                                        "",
                                        "success"
                                    );
                                }
                                else if (response.data == "Exist") {
                                    self.$swal("Error", "User already exist", "error");
                                }

                                self.$bvModal.hide("editContact");

                                self.getContacts(self.currentPage);
                                //this.modalShow=false;
                                self.isModalVisible = false;

                                self.loading = false;

                            })
                            .catch(function (error) {
                                self.loading = false;
                            });
                    }
                });
            },
            async showProject(Id) {
                this.hdnContactId = Id;

                var self = this;

                this.projects = [];
                self.Email = "";

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/ViewProjects?id=" + Id, {
                        headers,
                    })
                    .then((response) => {
                        if (response.data.length != 0) {
                            self.Email = response.data[0].ContactEmail;

                            document.getElementById("projectmodal___BV_modal_title_").innerHTML = 'Associated Projects <span style="font-size: 12px;font-weight: 400;">(' + self.Email + ")</span>";
                            // var element = document.getElementById("projectmodal___BV_modal_title_");
                            self.projects = response.data;
                            self.ProjectId = response.data[0].ProjectId;
                            self.loading = false;
                        }
                    });
            },

            clearSearchField() {
                this.SearchText = '';
                this.searchContact();
            },

            async searchContact(searchvalue) {
                //var pagenumber = this.currentPage;
                var pagenumber = 1;
                var self = this;
                var searchvalue = this.SearchText;
                var startValue = (pagenumber - 1) * this.perPage;
                var length = this.perPage;

                this.loading = true;
                //const access_token = jwt.getToken();
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/getConatcts?searchValue=" + searchvalue + "&startValue=" + startValue + "&length=" + length + "&IsServerSide=" + true, { headers })
                    .then((response) => {
                        self.items = response.data;
                        if (response.data.length != 0) {
                            this.totalRows = response.data[0].TotalCount;
                        } else {
                            this.totalRows = 1;
                        }
                        this.modalShow = true;
                        self.loading = false;
                    });
            },
            async addProject() {
                var self = this;
                this.loading = true;
                const access_token = await jwt.getToken();
                this.hdnContactId = 0;
                this.PrimaryEmailId = 0;
                this.ContactSubscriberId = 0;
                this.ProjectId = 0;
                this.LastName = null;
                this.IsSubscribed = true;
                this.ProjectAutomation = true;
                this.FirstName = null;
                this.Email = null;
                this.SelectProjects = null;

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/getAllProjects", {
                        headers,
                    })
                    .then((response) => {
                        self.UserFunnels = response.data;
                        this.modalShow = true;
                        self.loading = false;
                    });
            },
            async addContactForm() {
                var self = this;

                this.loading = true;
                const access_token = await jwt.getToken();

                this.$refs.simpleRules.validate().then((success) => {
                    if (success) {
                        var SelectProjectList = [];
                        var i;
                        for (i = 0; i < this.SelectProjects.length; i++) {
                            SelectProjectList.push(this.SelectProjects[i].Id);
                        }
                        var SelectProjectString = SelectProjectList.toString();

                        var qs = require("qs");

                        var axios = require("axios");
                        var data = qs.stringify({});
                        if (self.LastName === null) {
                            self.LastName = '';
                        }
                        var config = {
                            method: "post",
                            url: "" + this.WebAPIURL + "/api/ContactApi/UpdateContactInfo?hdnContactId=" + self.hdnContactId + "&PrimaryEmailId=" + self.PrimaryEmailId + "&ContactSubscriberId=" + self.ContactSubscriberId + "&FirstName=" + self.FirstName + "&LastName=" + self.LastName + "&PrimaryEmail=" + encodeURIComponent(encodeURIComponent(self.Email)) + "&ProjectId=" + self.ProjectId + "&IsSubscribed=" + self.IsSubscribed + "&UserFunnelsListValues=" + SelectProjectString + "&IsSendibbleContact=true&UnSubscribeFromAll=false&IsSendAutomationEmails=" + self.ProjectAutomation,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };

                        axios(config).then((response) => {
                            if (response.data == "Saved") {
                                self.$swal("Success", "Contact is added successfully", "success");
                                self.$bvModal.hide("addContactmodal");
                                self.getContacts(self.currentPage);
                            } else if (response.data == "LimitExceed") {
                                self.$swal(
                                    "Daily Limit Exceed",
                                    "You have reached the maximum allowed limit of adding manual contacts in a day.",
                                    "warning"
                                );
                            } else if (response.data == "Exist") {
                                self.$swal("Error While Saving", "User already exist", "error");
                            }
                            else if (response.data == "UpgradeUpSell") {
                                const externalURL = 'https://app.empirely.ai/upgrade';
                                this.$swal({
                                    title: "Limit Exceed!",
                                    html: "You have attempted to exceed the limit. Please upgrade your account to create a new contact.",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Upgrade",
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                        cancelButton: "btn btn-outline-danger ml-1",
                                    },
                                    buttonsStyling: false,
                                }).then((result) => {
                                    if (result.value) {
                                        window.open(externalURL, '_blank');
                                    }
                                });
                            }
                            self.loading = false;
                        });
                    }
                    self.loading = false;
                });
            },
            unsubscribing() {
                this.IsSubscribed = false;
            },
            doSubscribe() {
                this.IsSubscribed = true;
            },
            cancelAutomation() {
                this.ProjectAutomation = false;
            },
            doAutomation() {
                this.ProjectAutomation = true;
            },

            async unsubscribe(Id, ProjectId, data) {
                var self = this;

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/MarkUnsubscribeResubscribe?ContactId=" + Id + "&IsSubscribed=" + false + "&ProjectId=" + ProjectId, { headers }
                    )
                    .then((response) => {
                        self.getContacts(self.currentPage);
                        self.loading = false;
                        data.item.IsSubscribed = false;
                    });
            },
            async subscribeProject(Id, ProjectId, data) {
                var self = this;

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/MarkUnsubscribeResubscribe?ContactId=" + Id + "&IsSubscribed=" + true + "&ProjectId=" + ProjectId, { headers })
                    .then((response) => {
                        self.getContacts(self.currentPage);
                        self.loading = false;
                        data.item.IsSubscribed = true;
                    });
            },
            closeEditModal() {
                this.$bvModal.hide("editContact");
            },
        },
        destroyed() {
            //document.querySelector('a[href*="/contacts"]').parentNode.classList.remove("active");
        },
    };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
    a {
        color: #b4b7bd;
    }
</style>